<template>
  <div>
    <div class="loading">
      <div class="loader">
        <div>0</div>
        <div>距</div>
        <div>离</div>
        <div>选</div>
        <div>品</div>
        <div>平</div>
        <div>台</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LinkDistanceMallWebLoading",

  data() {
    return {
      loadimg: require("@/assets/img/loading.gif"),
      loading: true,
    };
  },

  mounted() {},

  methods: {},
};
</script>
<style scoped>
a {
  background: #13a3a5;
  padding: 5px;
  margin: 10px;
  display: block;
  font-weight: 100;
  cursor: pointer;
  font-size: 1.5em;
  float: left;
  text-decoration: none;
  font-size: 18px;
  color: white;
}
.loading {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -mos-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  /* disable selection and cursor changes */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  /* min-width: 33.25em; */
}

.loader div {
  position: relative;
  width: 35px;
  height: 35px;
  background: #fff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  margin: 0 5px;
  float: left;
  font-size: 18;
  line-height: 35px;
  -webkit-animation: bounce 1.5s infinite ease-in-out;
  -moz-animation: bounce 1.5s infinite ease-in-out;
  -mos-animation: bounce 1.5s infinite ease-in-out;
  -o-animation: bounce 1.5s infinite ease-in-out;
  animation: bounce 1.5s infinite ease-in-out;
}

/* .loader div:after {
  content: "";
  position: absolute;
  bottom: -2em;
  left: 0.35em;
  width: 1.8em;
  height: 0.5em;
  background: #322b27;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
} */

.loader div:nth-child(1) {
  -webkit-animation: bouncefirst 1.5s infinite ease-in-out;
  -moz-animation: bouncefirst 1.5s infinite ease-in-out;
  -mos-animation: bouncefirst 1.5s infinite ease-in-out;
  -o-animation: bouncefirst 1.5s infinite ease-in-out;
  animation: bouncefirst 1.5s infinite ease-in-out;
  -webkit-animation-delay: 0ms;
  -moz-animation-delay: 0ms;
  -ms-animation-delay: 0ms;
  -o-animation-delay: 0ms;
  animation-delay: 0ms;
  background: #db2f00;
  color: #fff;
}

.loader div:nth-child(2) {
  -webkit-animation-delay: 50ms;
  -moz-animation-delay: 50ms;
  -ms-animation-delay: 50ms;
  -o-animation-delay: 50ms;
  animation-delay: 50ms;
  background: #ff6d37;
  color: #fff;
}

.loader div:nth-child(3) {
  -webkit-animation-delay: 100ms;
  -moz-animation-delay: 100ms;
  -ms-animation-delay: 100ms;
  -o-animation-delay: 100ms;
  animation-delay: 100m;
  background: #ffa489;
  color: #fff;
}

.loader div:nth-child(4) {
  -webkit-animation-delay: 150ms;
  -moz-animation-delay: 150ms;
  -ms-animation-delay: 150ms;
  -o-animation-delay: 150ms;
  animation-delay: 150ms;
  background: #f2f2f2;
  color: #555;
}

.loader div:nth-child(5) {
  -webkit-animation-delay: 200ms;
  -moz-animation-delay: 200ms;
  -ms-animation-delay: 200ms;
  -o-animation-delay: 200ms;
  animation-delay: 200ms;
  background: #99d3d4;
  color: #fff;
}

.loader div:nth-child(6) {
  -webkit-animation-delay: 250ms;
  -moz-animation-delay: 250ms;
  -ms-animation-delay: 250ms;
  -o-animation-delay: 250ms;
  animation-delay: 250ms;
  background: #56bebf;
  color: #fff;
}

.loader div:nth-child(7) {
  -webkit-animation-delay: 300ms;
  -moz-animation-delay: 300ms;
  -ms-animation-delay: 300ms;
  -o-animation-delay: 300ms;
  animation-delay: 300ms;
  color: #fff;
  background: #13a3a5;
}

@-webkit-keyframes bouncefirst {
  0% {
    -webkit-transform: translateX(0px);
  }
  20% {
    -webkit-transform: translateX(-50px);
  }
  25% {
    -webkit-transform: translateX(-50px);
  }
  50% {
    -webkit-transform: translateX(100px);
  }
  80%,
  100% {
    -webkit-transform: translateX(0px);
  }
}

@-moz-keyframes bouncefirst {
  0% {
    -moz-transform: translateX(0px);
  }
  20% {
    -moz-transform: translateX(-50px);
  }
  25% {
    -moz-transform: translateX(-50px);
  }
  50% {
    -moz-transform: translateX(100px);
  }
  80%,
  100% {
    -moz-transform: translateX(0px);
  }
}

@-mos-keyframes bouncefirst {
  0% {
    -mos-transform: translateX(0px);
  }
  20% {
    -mos-transform: translateX(-50px);
  }
  25% {
    -mos-transform: translateX(-50px);
  }
  50% {
    -mos-transform: translateX(100px);
  }
  80%,
  100% {
    -mos-transform: translateX(0px);
  }
}

@-o-keyframes bouncefirst {
  0% {
    -o-transform: translateX(0px);
  }
  20% {
    -o-transform: translateX(-50px);
  }
  25% {
    -o-transform: translateX(-50px);
  }
  50% {
    -o-transform: translateX(100px);
  }
  80%,
  100% {
    -o-transform: translateX(0px);
  }
}

@keyframes bouncefirst {
  0% {
    transform: translateX(0px);
  }
  20% {
    transform: translateX(-50px);
  }
  25% {
    transform: translateX(-50px);
  }
  50% {
    transform: translateX(100px);
  }
  80%,
  100% {
    transform: translateX(0px);
  }
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateX(0px);
  }
  20% {
    -webkit-transform: translateX(0px);
  }
  28% {
    -webkit-transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(100px);
  }
  80%,
  100% {
    -webkit-transform: translateX(0px);
  }
}

@-moz-keyframes bounce {
  0% {
    -moz-transform: translateX(0px);
  }
  20% {
    -moz-transform: translateX(0px);
  }
  28% {
    -moz-transform: translateX(0px);
  }
  50% {
    -moz-transform: translateX(100px);
  }
  80%,
  100% {
    -moz-transform: translateX(0px);
  }
}

@-mos-keyframes bounce {
  0% {
    -mos-transform: translateX(0px);
  }
  20% {
    -mos-transform: translateX(0px);
  }
  28% {
    -mos-transform: translateX(0px);
  }
  50% {
    -mos-transform: translateX(100px);
  }
  80%,
  100% {
    -mos-transform: translateX(0px);
  }
}

@-o-keyframes bounce {
  0% {
    -o-transform: translateX(0px);
  }
  20% {
    -o-transform: translateX(0px);
  }
  28% {
    -o-transform: translateX(0px);
  }
  50% {
    -o-transform: translateX(100px);
  }
  80%,
  100% {
    -o-transform: translateX(0px);
  }
}

@keyframes bounce {
  0% {
    transform: translateX(0px);
  }
  20% {
    transform: translateX(0px);
  }
  28% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(100px);
  }
  80%,
  100% {
    transform: translateX(0px);
  }
}
</style>
