<template>
  <div class="card">
    <div class="product-img-container">
      <img :src="item.spuimage" alt="" class="product-img" />
    </div>
    <div class="product-info-container">
      <div class="product-name">
        {{ item.goodsNameCn }}
      </div>
      <div class="product-info">
        <span class="label" v-if="(item.inventory && type==='5') || (!item.customized&&type==='1')">处理价：</span>
        <span class="label" v-if="(type!=='5' && item.customized) || item.title==='私域礼品'">基础价：</span>
        <span class="factory-price-value">【点击查看】</span>
      </div>
      <div class="product-info" style="margin-bottom: 23px;" v-if="(type!=='5' && item.customized) || item.title==='私域礼品'">
        <span class="label">定制起订量：</span>
        <span class="label">{{ item.minOrder }}</span>
      </div>
      <div class="product-info" v-if="(item.inventory && type==='5') || (!item.customized&&type==='1')">
        <span class="label">现有库存：</span>
        <span class="label">{{ item.inventoryNumber }}</span>
      </div>
      <div class="product-info" v-if="(item.inventory && type==='5') || (!item.customized&&type==='1')">
        <span class="label">库存起批量：</span>
        <span class="label">{{ item.minInventory }}</span>
      </div>
      <div class="more-info">
        <div class="tag-container" v-if="(type!=='5' && item.customized) || item.title==='私域礼品'">
          <span
            v-for="(items, index) in item.specialTag"
            :key="index"
            class="tag"
            >{{ items }}</span
          >
        </div>
        <div class="tag-container" v-if="(item.inventory && type==='5') || (!item.customized&&type==='1')">
          <span
            v-for="(items, index) in item.inventoryTags"
            :key="index"
            class="tag"
            >{{ items }}</span
          >
        </div>
        <div class="browse-num">
          <a-icon type="eye" />
          <span>{{ item.readNumber }}</span>
        </div>
      </div>
    </div>
    <img src="../assets/img/decet.png" class="check-box" v-if="item.isDetect" />
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
    },
    type:{
      type:String
    }
  },
  data () {
    return {}
  },

  created () {
    if (this.item.specialTag && this.item.specialTag[0] === "") {
      this.item.specialTag = []
    }
  },

  methods: {},
};
</script>
<style scoped lang="scss">
.card {
  width: 224px;
  height: 375px;
  position: relative;
  background-color: #ffffff;
  border: solid 1px #d8dce6;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease-out;
  &:hover {
    border-color: transparent;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12);
  }
}
.check-box {
  position: absolute;
  bottom: 9.5rem;
  right: 0.1rem;
  font-size: 1.5rem;
  width: 2rem;
}

.product-img-container {
  width: 222px;
  height: 222px;
  overflow: hidden;
  text-align: center;
  .product-img {
    width: 100%;
    height: 100%;
  }
}

.product-info-container {
  box-sizing: border-box;
  padding: 16px;
}

.product-name {
  width: 100%;
  line-height: 17px;
  margin-bottom: 12px;
  color: #333333;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  font-weight: bold;
}

.product-info {
  width: 100%;
  height: 20px;
  line-height: 20px;
  margin-bottom: 1px;
  text-align: left;
  .factory-price-value {
    color: #e41e1e;
    font-size: 14px;
    font-weight: 500;
  }
}
.label {
  font-size: 12px;
  color: #999999;
}

.more-info {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  text-align: left;
  height:20px;
  .tag-container {
    overflow: hidden;
    .tag {
      display: inline-block;
      height: 16px;
      line-height: 16px;
      border-radius: 2px;
      border: solid 0.5px #fe8432;
      color: #fe8432;
      font-size: 10px;
      text-align: center;
      background: rgba(254, 132, 50, 0.08);
      box-sizing: border-box;
      padding: 0 6px;
      margin-right: 4px;
    }
  }
  .browse-num {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 12px;
      height: 12px;
      margin-right: px;
    }
    span {
      font-size: 10px;
      color: #333333;
      margin-left: 3px;
    }
  }
}
</style>
